import React, { useContext } from 'react';

import { Context } from '../index';

import './index.sass';


const TopSeo = () => {

  const { topSeoHeadline, topSeoContent } = useContext(Context);

  return (
    <div className="top-seo container max-width">
      <h2 className="top-seo__headline">
        { topSeoHeadline }
      </h2>
      <div className="top-seo__content" dangerouslySetInnerHTML={{ __html: topSeoContent }}/>
    </div>
  )
};

export default TopSeo;
