
export const handleListening = ({ HTMLTypeElement,
                                  inputId,
                                  listening,
                                  listeningLanguage,
                                  onOngoingFunction,
                                  setListening,
                                }) => {

  if (window.hasOwnProperty('webkitSpeechRecognition')) {
    const SpeechRecognition = webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    let finalTranscript = '';

    recognition.continous = true;
    recognition.interimResults = true;
    recognition.lang = listeningLanguage;

    if (listening) {
      recognition.start();
      recognition.onaudioend = () => setListening(false);

      recognition.onresult = (event) => {
        let interimTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;

          if (event.results[i].isFinal) {
            finalTranscript += transcript + ' ';

            let input = document.getElementById(inputId);
            let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window[HTMLTypeElement].prototype, "value").set;

            nativeInputValueSetter.call(input, finalTranscript.substring(0, finalTranscript.length - 1));

            let inputEvent = new Event("input", { bubbles: true });

            input.dispatchEvent(inputEvent);
          }

          else interimTranscript += transcript;
          onOngoingFunction && onOngoingFunction();
        }
      }
    } else {
      recognition.stop()
    }
  }
};
