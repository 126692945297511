import React, { useContext } from "react";

import BaseSlider from '../../SharedComponents/BaseSlider';
import { isMobile } from 'react-device-detect';
import LocationTemplate from '../../SharedComponents/LocationTemplate';
import { Context } from '../index';

import './index.sass';


const LatestLocations = () =>  {

  const {
    latestLocations,
    latestLocationsHeadline,
    subSite,
    siteKey,
  } = useContext(Context);


  return (
    <div className="latest-locations">
      <div className="container max-width">
        <div className="latest-locations__title">
          { latestLocationsHeadline }
        </div>
      </div>

      <div className="latest-locations__slider-wrapper container max-width">
        <BaseSlider fullWidth hideArrows withDots={isMobile}>
          { latestLocations.map(item => (
            <LocationTemplate
              data={item}
              hidePrice={true}
              key={item.uuid}
              section={subSite}
              showDate={true}
              siteKey={siteKey}
            />
          )) }
        </BaseSlider>
      </div>
    </div>
  )
};

export default LatestLocations;
