import React, { useState } from 'react';

import { apiProvider } from '../../../api/core';
import { handleListening } from '../../helpers/audio_input';
import InfoMessagePopup from '../InfoMessagePopup';
import Loader from '../Loader';
import { SvgIco } from '../Icons';

import './index.sass';


const AISearch = ({ siteKey }) => {

  const [focused, setFocused] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [query, setQuery] = useState('');
  const [requestError, setRequestError] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [speechListening, setSpeechListening] = useState(false);


  const toggleListen = () => {
    const listeningLanguage = siteKey === 'dk' ? 'da-DK' : 'en-US';

    handleListening({
      HTMLTypeElement: 'HTMLTextAreaElement',
      inputId: 'ai-search-transcript',
      listening: !speechListening,
      listeningLanguage,
      setListening: setSpeechListening,
    });

    setSpeechListening((prevState) => !prevState);
  };


  const handleChange = (e) => {
    const newQuery = e ? e.target.value : query;

    setQuery(newQuery);
    requestError && setRequestError(null);
  };


  const submitSearch = () => {
    setLoadingData(true);

    apiProvider.post('/lease/api/frontend/nlp_searches', { search_phrase: query })
      .then((data) => {
        setLoadingData(false);
        data.error && setRequestError(data.error);
        data.success && data.path && (window.location.href = data.path);
      })
      .catch(error => {
        console.log('Error: ', error);
        setShowInfoWindow(true);
      });
  };


  return (
    <div className={`ai-search ${requestError ? 'error' : ''}`}>

      <InfoMessagePopup
        customClass="error"
        duration={5000}
        handleClose={() => setShowInfoWindow(false)}
        message={I18n.t('apps.lb_showroom.detailed.order_form.error.message')}
        visible={showInfoWindow}
      />

      <div className={`ai-search__input-wrapper ${loadingData ? 'disabled' : ''} ${focused ? 'focused' : ''}`}>
        <SvgIco className="ai-search__search-icon" name="search-entry-page" size={24} />

        <textarea
          className="ai-search__input"
          disabled={loadingData}
          id="ai-search-transcript"
          onBlur={() => setFocused(false)}
          onChange={handleChange}
          onFocus={() => setFocused(true)}
          placeholder={I18n.t('entry_pages.ai_search.placeholder')}
          resize="none"
          type="text"
          value={query}
        />

        { window.hasOwnProperty('webkitSpeechRecognition') &&
          <div>
            <span
              className={`ai-search__voice-record ${speechListening ? 'active' : ''}`}
              onClick={toggleListen}>
              <SvgIco className="ai-search__mic-icon" name="mic" size={32} />
            </span>
          </div>
        }
      </div>

      <div className="ai-search__bottom-wrapper">
        <div className="ai-search__bottom-tooltip">
          { requestError || I18n.t('entry_pages.ai_search.tooltip') }
        </div>

        <div className={`ai-search__submit-button ${!query.length || loadingData || requestError ? 'disabled' : ''}`} onClick={submitSearch}>
          { I18n.t('generic.search') }
          { loadingData && <Loader size={24} /> }
        </div>
      </div>
    </div>
  )
};

export default AISearch;
