import React, { useContext } from 'react';

import { SvgIco } from '../../SharedComponents/Icons';
import { Context } from '../index';

import './index.sass';


const Benefits = () => {

  const { advantagesTranslations, country, searchAreaConfiguration } = useContext(Context);
  const preselectedKind = searchAreaConfiguration.searchOptions.preselectedKinds;
  const kindForTranslation = preselectedKind[0] || 'second';


  return (
    <div className="benefits container max-width">
      <h2 className="benefits__title">
        { I18n.t('apps.entry_page.benefits_headline') }
      </h2>
      <div className="row">
        <div className="col-xl-4 col-md-4">
          <div className="benefits__item">
            <div className="benefits__icon-wrapper">
              100%
            </div>
            <span className="benefits__description">
            { I18n.t(`apps.entry_page.search_area.value_points.${advantagesTranslations}.one_full_new`) }
          </span>
          </div>
        </div>
        <div className="col-xl-4 col-md-4">
          <div className="benefits__item">
            <div className="benefits__icon-wrapper">
              <SvgIco name="location" size={36} />
            </div>
            <span className="benefits__description">
              { I18n.t(`apps.entry_page.search_area.value_points.${advantagesTranslations}.${kindForTranslation}`, {'country_name': country}) }
            </span>
          </div>
        </div>
        <div className="col-xl-4 col-md-4">
          <div className="benefits__item">
            <div className="benefits__icon-wrapper">
              <SvgIco name="save" size={36} />
            </div>
            <span className="benefits__description">
              { I18n.t(`apps.entry_page.search_area.value_points.${advantagesTranslations}.third`) }
            </span>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Benefits;
