import React, { useContext, useEffect, useRef } from 'react';

import { SvgIco } from '../../SharedComponents/Icons';
import { Context } from '../index';

import './NavigationFilter.sass';


const NavigationFilter = ({ handleKindsSelect, selectedKinds, setNavigationFilterRef, topMenuHeight }) => {

  const { updatedKinds } = useContext(Context);

  const navigationFilterRef = useRef();

  const scrollTargetBottom = { bottom: topMenuHeight };

  useEffect(() => {
    setNavigationFilterRef(navigationFilterRef && navigationFilterRef.current);
  }, []);


  const handleKindClick = (e) => handleKindsSelect(e.target.value)


  return (
    <div className="navigation-filter">
      <div className="navigation-filter__kinds-wrapper">
        { updatedKinds.map(kind =>
          <label
            className="navigation-filter__kind-label"
            key={kind.key}
          >
            <input
              checked={selectedKinds.includes(kind.key)}
              className="navigation-filter__kind-checkbox"
              onChange={handleKindClick}
              type="checkbox"
              value={kind.key}
            />

            <div className="navigation-filter__checkbox-icon">
              <SvgIco name="check" size={16} />
            </div>

            { kind.label }
          </label>
        )}
      </div>

      <div
        className="navigation-filter__scroll-target"
        ref={navigationFilterRef}
        style={scrollTargetBottom}
      />
    </div>
  )
}

export default NavigationFilter;
