import React, { useEffect, useState, Suspense } from 'react';
import PropTypes from "prop-types";
import { createContext } from 'react';

import { apiProvider } from '../../api/core';
import { loadTawkTo } from '../TawkTo/TawkTo';
import { ToTheTopBtn } from '../SharedComponents/Buttons';
import PopularAreas from "../SharedComponents/PopularAreas";
import SearchAgentBanner from '../SharedComponents/SearchAgentBanner';
import ListViewBanner from './ListViewBanner/ListViewBanner';
import ProviderLogos from './ProviderLogos';
import BottomSeo from './BottomSeo';
import NewsSection from './NewsSection';
import TopSection from './TopSection';
import LatestLocations from './LatestLocations';
import LocationNavigationSection from "./LocationNavigation";
import Benefits from './Benefits';
import TopSeo from './TopSeo';

import './index.sass';


export const Context = createContext({});

const FAQSection = React.lazy(() => import('../SharedComponents/FAQSection'));


const EntryPage = (props) => {

  const {
    bottomSeo,
    currentLocation,
    entryPageQuestionsAnswers,
    isRequestFromPrerender,
    latestLocationsEndpoint,
    newsAvailable,
    newSearchAgentPath,
    popularDistricts,
    searchAgentHeadline,
    searchAgentContent,
    siteKey,
  } = props;


  const [latestLocations, setLatestLocations] = useState([]);
  const [showParkingLogos, setShowParkingLogos] = useState(false);

  const showNews = newsAvailable && (siteKey === 'global' ? (currentLocation !== 'tr') : (siteKey !== 'no'));


  useEffect(() => {
    loadTawkTo(siteKey);
    latestLocationsEndpoint && apiProvider
      .getAll(latestLocationsEndpoint)
      .then(response => response && setLatestLocations(response));
  }, []);


  const handleParkingLogos = () => setShowParkingLogos(prevState => !prevState);

  const contextProps = {
    ...props,
    handleParkingLogos,
    latestLocations,
    showParkingLogos,
  };


  return (
    <Context.Provider value={contextProps}>
      <div className="entry-page">
        <div className="entry-page__content">

          <TopSection />

          <LocationNavigationSection />

          <Benefits />

          <ProviderLogos />

          <Suspense fallback={''}>
            <LatestLocations />
          </Suspense>

          <PopularAreas
            isRequestFromPrerender={isRequestFromPrerender}
            popularDistricts={popularDistricts}
          />

          { searchAgentContent && searchAgentHeadline &&
            <SearchAgentBanner
              newSearchAgentPath={newSearchAgentPath}
              searchAgentHeadline={searchAgentHeadline}
              searchAgentText={searchAgentContent}
              siteKey={siteKey}
            />
          }

          { showNews && <NewsSection /> }

          <TopSeo />

          <ListViewBanner />

          { bottomSeo && <BottomSeo /> }
        </div>


        { entryPageQuestionsAnswers && entryPageQuestionsAnswers.length > 0 &&
          <Suspense fallback={''}>
            <FAQSection
              isRequestFromPrerender={isRequestFromPrerender}
              richSnippetQuestions={entryPageQuestionsAnswers}
              title={I18n.t('provider.teaser.faq_questions.title-entry-page')}
            />
          </Suspense>
        }

        <ToTheTopBtn />
      </div>
    </Context.Provider>
  );
};

export default EntryPage;


EntryPage.propTypes = {
  aboutUsContent: PropTypes.string,
  topSeoHeadline: PropTypes.string,
  advantagesTranslations: PropTypes.string,
  backgroudImages: PropTypes.arrayOf(PropTypes.string),
  bottomSeo: PropTypes.bool,
  bottomSeoBody: PropTypes.string,
  bottomSeoCompleteBody: PropTypes.string,
  bottomSeoHeadline: PropTypes.string,
  chooseProvinceJsArgs: PropTypes.shape({
    basePathsForKinds: PropTypes.object,
    districtBasePath: PropTypes.string,
    globalStatsUrl: PropTypes.string,
    localePart: PropTypes.string,
    provinceBasePath: PropTypes.string,
    statsProvincesOrder: PropTypes.string,
    statsUrl: PropTypes.string,
  }),
  contactForm: PropTypes.object,
  contactFormAvailable: PropTypes.bool,
  currentLocation: PropTypes.string,
  navigationSeoContent: PropTypes.string,
  searchAgentHeadline: PropTypes.string,
  searchAgentContent: PropTypes.string,
  latestLocations: PropTypes.arrayOf(PropTypes.object),
  latestLocationsHeadline: PropTypes.string,
  logo: PropTypes.string,
  mainHeadline: PropTypes.string,
  mapImg: PropTypes.string,
  nav: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object,
  }),
  newsAvailable: PropTypes.bool,
  newSearchAgentPath: PropTypes.string,
  popularDistricts: PropTypes.arrayOf(PropTypes.object),
  providerLogos: PropTypes.arrayOf(PropTypes.array),
  providerParkingLogos: PropTypes.arrayOf(PropTypes.string),
  providerTrustedLogos: PropTypes.arrayOf(PropTypes.object),
  searchAreaConfiguration: PropTypes.object,
  searchBlockHeader: PropTypes.string,
  signUpTeaserPass: PropTypes.string,
  siteKey: PropTypes.string,
  subSite: PropTypes.string,
  currentLocalePathSegment: PropTypes.string,
  trustedLogosUrl: PropTypes.string,
};


EntryPage.defaultProps = {
  aboutUsContent: '',
  topSeoHeadline: '',
  advantagesTranslations: '',
  backgroudImages: [],
  bottomSeo: false,
  bottomSeoBody: '',
  bottomSeoCompleteBody: '',
  bottomSeoHeadline: '',
  chooseProvinceJsArgs: {
    basePathsForKinds: {},
    districtBasePath: '',
    globalStatsUrl: '',
    localePart: '',
    provinceBasePath: '',
    statsProvincesOrder: '',
    statsUrl: '',
  },
  contactForm: {},
  currentLocation: '',
  navigationSeoContent: '',
  searchAgentHeadline: '',
  searchAgentContent: '',
  latestLocations: [],
  latestLocationsHeadline: '',
  logo: '',
  mainHeadline: '',
  mapImg: '',
  nav: { left: {}, right: {} },
  newsAvailable: false,
  newSearchAgentPath: '',
  popularDistricts: [],
  providerLogos: [],
  providerParkingLogos: [],
  providerTrustedLogos: [],
  searchAreaConfiguration: {},
  searchBlockHeader: '',
  signUpTeaserPass: '',
  siteKey: '',
  subSite: '',
  currentLocalePathSegment: '',
  trustedLogosUrl: '',
};
