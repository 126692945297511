import React, { useEffect, useState } from 'react';

import { SvgIco } from "../Icons";

import './index.sass';

let timer;


const InfoMessagePopup = ({ customClass, duration, handleClose, message, visible }) => {

  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    setAnimationClass(visible ? 'fadein' : 'fadeout');
    visible && (timer = setTimeout(() => handleClose(), duration || 3000));
  }, [visible]);


  const closePopup = () => {
    clearTimeout(timer);
    handleClose();
  };


  return (
    <div className={`notification-popup ${animationClass} ${customClass || ''}`} role="button">
      <div className="notification-popup__close" onClick={closePopup} role="button">
        <SvgIco name="close" size={20} />
      </div>
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  )
}

export default InfoMessagePopup;
