import React, { useCallback, useEffect, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import { PrevButton, NextButton, usePrevNextButtons } from './NavigationButtons'

import './index.sass';


const EmblaCarousel = ({ carouselName,
                         disableScroll,
                         fullWidth,
                         handleChange,
                         hideArrows,
                         multiSlidesPerView,
                         options,
                         slides,
                         withDots
                       }) => {

  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const dotsIWrapper = useRef();

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick} = usePrevNextButtons(emblaApi)

  const disableScrollControls = slides.length <= 1 || disableScroll;
  const slidesData = disableScrollControls ? [ slides[0] ] : slides;
  const allSlidesInView = scrollSnaps && scrollSnaps.length === 1;


  const logSlidesInView = useCallback(() => {
    handleChange();
  }, [])


  useEffect(() => {
    emblaApi && handleChange && emblaApi.on('scroll', logSlidesInView);
  }, [emblaApi, handleChange, logSlidesInView]);


  useEffect(() => {
    const slidesCount = slides && slides.length;

    if (withDots && slidesCount > 5 && dotsIWrapper && dotsIWrapper.current) {
      const slideWidth = 14;

      if (selectedIndex === 0) {
        dotsIWrapper.current.scrollLeft = 0;
      } else if (selectedIndex === (slidesCount - 1)) {
        const scrollLeft = (slidesCount - 5) * slideWidth;

        dotsIWrapper.current.scrollLeft = scrollLeft;
      } else {
        const scrollLeft = (slideWidth * (selectedIndex - 2));

        dotsIWrapper.current.scrollLeft = scrollLeft;
      };
    }
  }, [selectedIndex]);


  return (
    <section className={`embla-${carouselName} embla${multiSlidesPerView ? ' multi-slides' : ''}${fullWidth ? ' full-width' : ''}`}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          { slidesData.map((slide, index) =>
            <div className="embla__slide" key={`embla_slide_${carouselName}_${index}`}>
              { slide }
            </div>
          )}
        </div>
      </div>

      <div className="embla__controls">

        { !disableScrollControls && !hideArrows && !allSlidesInView &&
          <div className="embla__buttons">
            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
          </div>
        }

        { withDots && !disableScrollControls && !allSlidesInView &&
          <div className="embla__dots-wrapper">
            <div className="embla__dots" ref={dotsIWrapper}>
              { scrollSnaps.map((_, index) =>
                <DotButton
                  className={'embla__dot'.concat(
                    index === selectedIndex ? ' embla__dot--selected' : ''
                  )}
                  id={`embla-dot-${index}`}
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                />
              )}
            </div>
          </div>
        }
      </div>
    </section>
  )
}

export default EmblaCarousel
