import React, { useContext, useEffect, useRef, useState } from 'react';

import * as helpers from '../../../utils/helpers';
import { Context } from '../index';
import NavigationFilter from './NavigationFilter';
import LocationNavigator from './LocationNavigator';

import './index.sass';


const LocationNavigationSection = () => {

  const {
    allCountries,
    chooseProvinceJsArgs,
    currentLocalePathSegment,
    handleParkingLogos,
    mapImg,
    navigationSeoContent,
    searchAreaConfiguration,
    siteKey,
  } = useContext(Context);

  const [selectedKinds, setSelectedKinds] = useState([]);
  const [navigationFilterRef, setNavigationFilterRef] = useState('');

  const navigationContainerRef = useRef();

  const topMenuHeight = document.getElementById('top-menu').offsetHeight;
  const scrollTargetTop = { top: -topMenuHeight };


  useEffect(() => {
    const preselectedKinds = searchAreaConfiguration.searchOptions.preselectedKinds;

    preselectedKinds.length > 0 && preselectedKinds.map(elem => handleKindsSelect(elem));
  }, []);


  const handleKindsSelect = (kind) => {
    const updatedKinds = selectedKinds.indexOf(kind) >= 0 ? selectedKinds.filter(selectedKind => selectedKind !== kind) : [...selectedKinds, kind];

    setSelectedKinds(updatedKinds);
    kind === 'parking' && handleParkingLogos();
  };


  return (
    <div className="geo-navigation container max-width">
      <h2 className="geo-navigation__title">
        { siteKey === 'global'
          ? I18n.t('entry_pages.choose_district.title')
          : I18n.t('entry_pages.choose_province.title')
        }
      </h2>

      <div className="geo-navigation__scroll-target"
           ref={navigationContainerRef}
           style={scrollTargetTop} />

      <div className="row">
        <div className="geo-navigation__welcome-content col-xl-9 col-lg-11 col-md-12"
             dangerouslySetInnerHTML={{ __html: helpers.parseMd(navigationSeoContent) }} />
      </div>

      <NavigationFilter
        handleKindsSelect={handleKindsSelect}
        selectedKinds={selectedKinds}
        setNavigationFilterRef={setNavigationFilterRef}
        topMenuHeight={topMenuHeight}
      />

      <LocationNavigator
        allCountries={allCountries}
        chooseProvinceJsArgs={chooseProvinceJsArgs}
        currentLocalePathSegment={currentLocalePathSegment}
        navigationContainerRef={navigationContainerRef && navigationContainerRef.current}
        navigationFilterRef={navigationFilterRef}
        mapImg={mapImg}
        selectedKinds={selectedKinds}
        siteKey={siteKey}
      />
    </div>
  )
};

export default LocationNavigationSection;
