import React, { useContext, useEffect, useState } from 'react';
import update from 'immutability-helper';
import { isMobileOnly } from 'react-device-detect';

import defaultTitleImage from '../../../src/images/entry_pages/title.webp';
import { fetchLocations } from '../../../utils/api';
import { getObjectIndexInArray } from '../../helpers/array';
import { SvgIco } from '../../SharedComponents/Icons';
import AISearch from '../../SharedComponents/AISearch';
import { Context } from '../index';
import BasicSearch from './BasicSearch';
import CheckboxTypeFilter from './CheckboxTypeFilter';

import './index.sass';


const TopSection = () => {

  const {
    contactInfoPhone,
    contactInfoPhoneWithoutCode,
    mainHeadline,
    searchAreaConfiguration,
    searchBlockHeader,
    searchQueryString,
    siteKey,
    subSite,
    titleImage,
    updatedKinds,
  } = useContext(Context);


  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [selectedAvailableKinds, setSelectedAvailableKinds] = useState([]);
  const [selectedKindsUpdated, setSelectedKindsUpdated] = useState([]);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);
  const [selectedSearchId, setSelectedSearchId] = useState(0);
  const [showNoResultsError, setShowNoResultsError] = useState(false);

  let availableSections, availableKinds;
  const search_id = new Date().getTime() % 2147483647;
  const locationTypesMapped = updatedKinds.map(elem => ({ name: elem.label, id: elem.key }));
  const phoneNumber = siteKey === 'global' ? contactInfoPhone : contactInfoPhoneWithoutCode;


  useEffect(() => {
    const preselectedKinds = searchAreaConfiguration.searchOptions.preselectedKinds;

    preselectedKinds.length > 0 && preselectedKinds.map(elem => handleKindsSelect(elem));

    // except this only for 'sales section' investment checkbox
    subSite === 'sales'
      && searchAreaConfiguration.searchOptions.preselectedSections === "investment"
      && setSelectedPropertyTypes([{id: 'investment'}]);
  }, []);


  useEffect(() => {
    setSelectedPropertyTypes(mapPreselectKinds());
  }, [selectedKindsUpdated]);


  const mapPreselectKinds = () =>
    locationTypesMapped.filter(elem => {
      if (selectedKindsUpdated.includes(elem.id)) {
        return elem;
      }
    });


  const handleKindsSelect = (kind) => {
    const newUpdatedKinds = selectedKindsUpdated.indexOf(kind) >= 0
      ? selectedKindsUpdated.filter(selectedKindsUpdated => selectedKindsUpdated !== kind)
      : [...selectedKindsUpdated, kind];

    setSelectedKindsUpdated(newUpdatedKinds);
  };


  const handleKindsChange = (kind, selectedKindsGroup, setSelectedKindsGroup) => {
    const index = getObjectIndexInArray(selectedKindsGroup, 'id', kind.id)
    const itemIsSelected = index !== -1;

    setSelectedKindsGroup(itemIsSelected
      ? update(selectedKindsGroup, {$splice: [[index, 1]] })
      : update(selectedKindsGroup, {$push: [kind]})
    );

    /\S/.test(query) && fetchItems(query);
  };


  const mapTypes = typesObj => typesObj.map(typeChk => typeChk.id);


  const fetchItems = query => {
    const checkSalesSection = subSite === 'sales';
    const kinds = mapTypes(checkSalesSection ? selectedAvailableKinds : selectedPropertyTypes);
    const tabletOrMobile = window.innerWidth <= 991;
    let sections = mapTypes(selectedPropertyTypes);

    sections.length === 0 && (sections = ['foreclosure', 'investment', 'own_use']);

    const params = {
      query,
      kinds,
      search_id: search_id,
      sections: checkSalesSection ? sections : [window.env.section],
      max_entries: tabletOrMobile ? 8 : 12
    };

    fetchLocations(searchAreaConfiguration.apiAutocompletePath, params)
      .then(data => {
        setResults(data.data);
        setShowNoResultsError(!data.data.length);
      }).catch(error => console.log('Error', error));
  };


  const mapAvailableSections = (availableSections) =>
    availableSections.map(elem => ({ name: elem.label, id: elem.key }));


  if (subSite === 'sales') {
    availableSections = mapAvailableSections(searchAreaConfiguration.searchOptions.availableSections)
    availableKinds = mapAvailableSections(updatedKinds)
  };


  return (
    <div className="top-section">

      <div className="top-section__grey-background">
        { !isMobileOnly &&
          <div className="top-section__title-image">
            {/*{ titleImage*/}
            {/*  ?*/}
            {/*    <img*/}
            {/*      alt="title illustration"*/}
            {/*      className="lazyload"*/}
            {/*      data-sizes="auto"*/}
            {/*      data-src={titleImage}*/}
            {/*      height={140}*/}
            {/*      width={249}*/}
            {/*    />*/}
            {/*  :*/}
            {/*    <SvgIco*/}
            {/*      className="top-section__title-svg"*/}
            {/*      name="entry-page-title"*/}
            {/*      size="100%"*/}
            {/*    />*/}
            {/*}*/}
            <img
              alt="title illustration"
              className="lazyload"
              data-sizes="auto"
              data-src={titleImage || defaultTitleImage}
              height={140}
              width={249}
            />
          </div>
        }
      </div>

      <div className="container max-width">
        <div className="row">
          <div className="col-md-12">
            { contactInfoPhone &&
              <div className="top-section__phone-block">
                { I18n.t(`generic.call_us`) }:&nbsp; <a href={`tel://${contactInfoPhone}`}>{ phoneNumber }</a>
              </div>
            }
            <div className="top-section__title-wrapper">
              <div className="top-section__title-text">
                <h1 className="top-section__main-title">
                  { mainHeadline }
                </h1>
                <h2 className="top-section__search-title" dangerouslySetInnerHTML={{__html: searchBlockHeader }} />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="top-section__search-container container max-width">
        <div className="top-section__search-wrapper">
          <div className={`top-section__search-inner-container ${selectedSearchId === 0 ? 'basic' : 'ai'}-search-active`}>

            <div className="top-section__search-block-tabs">
              <div className={`top-section__search-block-tab basic ${selectedSearchId === 0 ? 'active' : ''}`} onClick={() => setSelectedSearchId(0)}>
                <SvgIco name="search-entry-page" size={14} />
                { I18n.t('entry_pages.main_search.tab_label') }
              </div>

              <div className={`top-section__search-block-tab ai ${selectedSearchId === 1 ? 'active' : ''}`} onClick={() => setSelectedSearchId(1)}>
                <SvgIco name="ai" size={14} />
                { I18n.t('entry_pages.ai_search.tab_label') }
              </div>
            </div>

            <div className="top-section__basic-search-wrapper">
              <div>
                <h3>{ I18n.t('entry_pages.main_search.find_premises') }</h3>

                { subSite === 'sales'
                  ?
                    <div className="top-section__sales-wrapper">
                      <CheckboxTypeFilter
                        dynamicData={availableSections}
                        placeholder={I18n.t('apps.lb_showroom.main.sections_placeholder')}
                        selectAction={(kind) => handleKindsChange(kind, selectedPropertyTypes, setSelectedPropertyTypes)}
                        selectedData={selectedPropertyTypes}
                        subSite={subSite}
                      />
                      <CheckboxTypeFilter
                        dynamicData={availableKinds}
                        placeholder={I18n.t('apps.lb_showroom.main.kinds_placeholder')}
                        selectAction={(kind) => handleKindsChange(kind, selectedAvailableKinds, setSelectedAvailableKinds)}
                        selectedData={selectedAvailableKinds}
                        subSite={subSite}
                      />
                    </div>
                  :
                    <CheckboxTypeFilter
                      dynamicData={locationTypesMapped}
                      selectAction={(kind) => handleKindsChange(kind, selectedPropertyTypes, setSelectedPropertyTypes)}
                      selectedData={selectedPropertyTypes}
                    />
                }
              </div>

              <BasicSearch
                clearResults={() => setResults([])}
                controlSearchQuery={(query) => setQuery(query)}
                fetchItems={fetchItems}
                results={results}
                searchQueryStringFromGoogle={searchQueryString}
                showNoResultsError={showNoResultsError}
                siteKey={siteKey}
              />
            </div>

            <div className="top-section__ai-search-wrapper">
              <AISearch siteKey={siteKey} />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
