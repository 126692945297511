import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from "prop-types";

import { SvgIco } from '../../../SharedComponents/Icons';
import { useDebouncedEffect } from '../../../SharedComponents/hooks';
import { handleListening } from '../../../helpers/audio_input';

import './index.sass';


const BasicSearch = ({ clearResults,
                       controlSearchQuery,
                       fetchItems,
                       results,
                       searchQueryStringFromGoogle,
                       showNoResultsError,
                       siteKey
                     }) => {

  const [formattedResults, setFormattedResults] = useState([]);
  const [focusInputField, setFocusInputField] = useState(false);
  const [query, setQuery] = useState('');
  const [resultContainerIsOpen, setResultContainerIsOpen] = useState(true);
  const [searchError, setSearchError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedResultPath, setSelectedResultPath] = useState(null);
  const [speechListening, setSpeechListening] = useState(false);

  const wrapperRef = useRef();

  const isInputFilled = !!query.length;


  useEffect(() => {
    if (searchQueryStringFromGoogle) {
      setQuery(searchQueryStringFromGoogle);
      handleInput(null, searchQueryStringFromGoogle);
    }
  }, []);


  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () =>
      document.removeEventListener('click', handleClickOutside);
  }, []);


  useEffect(() => {
    results && results.length
      ? setSelectedIndex(0)
      : setFormattedResults([]);
  }, [results]);


  useEffect(() => {
    let index = 0;

    const newResults = results && results.length && results.map(searchResultsSection => {

      const updatedEntries = searchResultsSection.entries.map(searchResult => {

        const updatedResults = { ...searchResult, selected: index === selectedIndex };

        index === selectedIndex && setSelectedResultPath(searchResult.path);
        index++;

        return updatedResults;
      });

      return ({ ...searchResultsSection, entries: updatedEntries });
    });

    setFormattedResults(newResults || []);
  }, [selectedIndex]);


  useDebouncedEffect(() => onChangeDebounced(), [query], 300);


  const handleEnter = () => {
    if (selectedResultPath && formattedResults.length !== 0) {
      clearInput();
      window.open(selectedResultPath, "_self");
    }
  };


  const handleDirection = (direction) => {
    const maxIndex = formattedResults.length ? formattedResults.map(el => el.entries.length).reduce((e, n) => e + n) - 1 : 0;

    direction === 'down' && setSelectedIndex(selectedIndex >= maxIndex ? 0 : selectedIndex + 1);
    direction === 'up' && setSelectedIndex(selectedIndex === 0 ? maxIndex : selectedIndex - 1);
  };


  const onChangeDebounced = () =>
    /\S/.test(query) ? fetchItems(query) : clearResults();


  const handleInput = (event, newQuery) => {
    const updatedQuery = event ? event.target.value.substring(0, 256) : newQuery || query;

    setQuery(updatedQuery);
    searchError && setSearchError(false);
    controlSearchQuery(updatedQuery);
  };


  const handleKeyDown = e => {
    switch (e.which) {
      case 38:
        handleDirection('up');
        break;
      case 40:
        handleDirection('down');
        break;
      case 27:
        handleDirection(0);
        clearInput();
        break;
      case 13:
        handleEnter();
        break;
      default:
        handleDirection(null);
    }
  };


  const handleClickOutside = (event) => {
    const isCheckbox = event.target.closest('.types-filter__checkbox-wrapper') || event.target.matches('.types-filter__checkbox-wrapper');

    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      !isCheckbox && setFocusInputField(false);
      setResultContainerIsOpen(isCheckbox);
    } else {
      setResultContainerIsOpen(true);
    }
  };


  const toggleListen = () => {
    const listeningLanguage = siteKey === 'dk' ? 'da-DK' : 'en-US';

    const onOngoingFunction = () => {
      setFocusInputField(true);
      setResultContainerIsOpen(true);
    };

    handleListening({
      HTMLTypeElement: 'HTMLInputElement',
      inputId: 'transcript',
      listening: !speechListening,
      listeningLanguage,
      onOngoingFunction,
      setListening: setSpeechListening,
    });

    setSpeechListening(prevState => !prevState);
  };


  const searchByLink = () =>
    query.length === 0
      ? setSearchError(true)
      : formattedResults.length === 0
      ? setSelectedResultPath(null)
      : window.open(selectedResultPath, "_self");


  const clearInput = () => {
    clearResults();
    setQuery('');
  };


  return (
    <div className="basic-search">
      <div className="basic-search__input-section">
        <div className={`basic-search__input-wrapper ${focusInputField ? 'focus' : ''} ${isInputFilled && showNoResultsError ? 'error' : '' }`}>
          <SvgIco name="search-entry-page" size={isMobile ? 18 : 25} />
          <input
            className="basic-search__input"
            id="transcript"
            maxLength="256"
            placeholder={I18n.t('entry_pages.main_search.input_placeholder')}
            onChange={handleInput}
            onFocus={() => setFocusInputField(true)}
            onKeyDown={handleKeyDown}
            ref={wrapperRef}
            type="text"
            value={query}
          />

          { isInputFilled &&
            <div className="basic-search__clear-btn" onClick={clearInput}>
              <SvgIco name="close" size={24} />
            </div>
          }

          { window.hasOwnProperty('webkitSpeechRecognition') &&
            <div className={`basic-search__voice-record ${ speechListening ? 'active' : '' }`}
                 onClick={toggleListen}>
              <SvgIco name="mic" size={32} />
            </div>
          }

          { !!(isInputFilled && resultContainerIsOpen && formattedResults.length) &&
            <div className="basic-search__dropdown-body">
              { formattedResults.map(location =>
                <div className="basic-search__dropdown-body-section" key={location.label}>

                  <div className="basic-search__dropdown-body-section-label">
                    { location.label }
                  </div>

                  { location.entries.map((item, index) => {

                    const { line, path, teaser } = item;

                    return (
                      <a className={`basic-search__dropdown-body-option ${item.selected ? 'selected' : ''}`}
                         href={path}
                         key={path + index}
                      >
                        <span className="basic-search__dropdown-body-option-text">{ line }</span>
                        <span className="basic-search__dropdown-body-option-teaser"> { teaser }</span>
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
          }
        </div>
      </div>

      { searchError &&
        <div className="basic-search__error-wrapper">
          <div className="basic-search__error-text">
            { I18n.t('apps.lb_showroom.goto.no_query') }
          </div>
        </div>
      }

      { isInputFilled && showNoResultsError &&
        <div className="basic-search__error-wrapper">
          <div className="basic-search__error-text">
            { I18n.t('apps.lb_showroom.goto.no_results') }&nbsp;
            { siteKey !== 'global' &&
              <a href="https://www.matchoffice.com">
                { I18n.t('apps.lb_showroom.goto.link_to_global_site') }
              </a>
            }
          </div>
        </div>
      }

      <div className="basic-search__submit-button-wrapper">
        <div className="basic-search__submit-button-tooltip">
          { I18n.t('entry_pages.main_search.tooltip') }
        </div>
        <div className="basic-search__submit-button" onClick={() => searchByLink()}>
          { I18n.t('generic.search') }
        </div>
      </div>

    </div>
  );
};

export default BasicSearch;


BasicSearch.propTypes = {
  results: PropTypes.array,
};

BasicSearch.defaultProps = {
  results: [],
};
