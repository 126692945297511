import React, { useContext } from 'react';

import { Context } from '../index';

import './index.sass';


const ProviderLogos = () => {

  const {
    currentLocation,
    providerLogos,
    providerLogosLink,
    providerParkingLogos,
    showParkingLogos,
  } = useContext(Context);

  const providerCurrentLogos = providerLogos.filter(logos => logos.indexOf(currentLocation) >= 0)[0][1];
  const logos = showParkingLogos ? providerParkingLogos : providerCurrentLogos;


  return (
    <div className="provider-logos container max-width">
      <div className="provider-logos__wrapper">
        <div className="provider-logos__headline">
          { I18n.t('entry_pages.partners_banner.text') }
        </div>
        <div className="provider-logos__images-list">
          { logos.map(logo =>
            <a className="provider-logos__image-link" href={providerLogosLink} key={logo} target="_blank">
              <img alt={logo}
                   className="lazyload"
                   data-expand="100"
                   data-sizes="auto"
                   data-src={require(`../../../src/images/entry_pages/provider_logos/${logo}`)}
              />
            </a>
          )}
        </div>
      </div>
    </div>
  )
};

export default ProviderLogos;
