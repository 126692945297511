import React, { useContext } from 'react';
import { isMobileOnly } from 'react-device-detect';

import bannerIllustration from '../../../src/images/entry_pages/banners-illustration.svg';
import { SvgIco } from '../../SharedComponents/Icons';
import { Context } from '../index';

import './ListViewBanner.sass';


const ListViewBanner = () => {

  const { signUpTeaserPass } = useContext(Context);


  return (
    <div className="list-view-banner">
      <div className="container max-width">
        <div className="list-view-banner__background-wrapper lazyload">
          <div className="list-view-banner__content-wrapper">
            <div className="list-view-banner__content">

              <p className="list-view-banner__title">
                { I18n.t('entry_pages.list_view_banner.main_title') }
              </p>

              <p className="list-view-banner__description">
                { I18n.t('entry_pages.list_view_banner.description') }
              </p>

              <a className="b-button btn-orange" href={signUpTeaserPass}>
                { I18n.t("entry_pages.list_view_banner.button") }
                <SvgIco name="keyboard-arrow-right-rounded" size={28} />
              </a>
            </div>

            { !isMobileOnly &&
              <div className="list-view-banner__illustration-wrapper">
                <img
                  alt="banner illustration"
                  className="lazyload"
                  data-expand="100"
                  data-sizes="auto"
                  data-src={bannerIllustration}
                  height={160}
                  width={215}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListViewBanner;
