import React from 'react';

import { arrayIncludesObject } from '../../../helpers/array';
import { SvgIco } from '../../../SharedComponents/Icons';

import './index.sass';


const CheckboxTypeFilter = ({ dynamicData,
                              selectAction,
                              selectedData,
                              placeholder,
                              subSite,
                            }) => {


  const itemIsSelected = (id) => arrayIncludesObject(selectedData, 'id', id);


  const optionsList =
    dynamicData.map(item =>
      <div className={`types-filter__checkbox-wrapper ${itemIsSelected(item.id) ? "types-filter__checkbox-wrapper--selected" : '' }`}
           key={item.id}
           onClick={() => selectAction(item)}>
        <p>{ item.name }</p>
        <SvgIco name="check" size={16} />
      </div>
    );

  return (
    <div className="types-filter">
      { subSite === 'sales'
        ?
          <div className='types-filter__sales-wrapper'>
            <div className="types-filter__title col-md-2 no-padding">
              <p>{ placeholder }</p>
            </div>
            <div className="types-filter__mobile-columns col-md-10 no-padding">
              { optionsList }
            </div>
          </div>
        :
          <div className="types-filter__wrapper">
            { optionsList }
          </div>
      }
    </div>
  );
};


export default CheckboxTypeFilter;
