import "scroll-behavior-polyfill";

import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import EmblaCarousel from "../EmblaCarousel";

import './index.sass';


const BaseSlider = ({ children, fullWidth, hideArrows, infinite, withDots }) => {

  const OPTIONS = { align: 'start', loop: !!infinite && !isMobileOnly };
  const SLIDES = [ ...children ];

  return (
    <div className="base-slider">
      <EmblaCarousel
        carouselName="multi-slider"
        fullWidth={fullWidth}
        hideArrows={hideArrows}
        multiSlidesPerView
        options={OPTIONS}
        slides={SLIDES}
        withDots={withDots}
      />
    </div>
  )
};

export default BaseSlider;
