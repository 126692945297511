import { useEffect, useCallback, useRef, useState, useMemo } from "react";
import ReactDOM from 'react-dom';
import { useLocation } from "react-router-dom";


export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps || [], delay]);
};


export const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb;
    setState(state);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
};


export const Portal = ({ parentId,
                         children,
                         className = 'root-portal',
                         element = 'div',
                       }) => {

  const [container] = useState(() => {
    const el = document.createElement(element);
    el.classList.add(className);
    return el;
  });

  const parentNode = parentId ? document.getElementById(parentId) : document.body;

  useEffect(() => {
    parentNode.appendChild(container)
    return () => {
      parentNode.removeChild(container)
    }
  }, []);

  return ReactDOM.createPortal(children, container)
};


export function useRouterQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
