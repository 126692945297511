import React, { useEffect, useState } from 'react';

import { SvgIco } from "../Icons";

import './index.sass';


const PopularAreas = ({ isRequestFromPrerender, popularDistricts }) => {

  const [isOpen, setIsOpen] = useState(true);
  const [collapsible, setCollapsible] = useState(false);


  useEffect(() => {
    handleCollapsible();
    !isRequestFromPrerender && setIsOpen(false);
  }, []);


  const handleCollapsible = () => {
    const linksListHeight = document.getElementById('popular-areas-list').offsetHeight;
    const windowWidth = window.innerWidth;
    const limitedHeight = windowWidth >= 992 ? 34 : 84;

    setCollapsible(linksListHeight > limitedHeight);
  };


  return (
    <div className={`popular-areas ${collapsible ? 'collapsible' : ''} ${isOpen ? 'open' : 'close'}`}>
      <div className="container">

        <h2 className="popular-areas__title">
          { I18n.t('apps.entry_page.entry_pages.popular_areas.headline') }
        </h2>

        <div className="popular-areas__list" id="popular-areas-list">
          { popularDistricts.map((link, index) =>
            <a href={link.url} className="popular-areas__link" key={index}>
              { link.label }
            </a>
          )}
        </div>

        { collapsible &&
          <div className="popular-areas__collapse-switcher" onClick={() => setIsOpen(prevState => !prevState)}>
          <span>
            { isOpen
              ? I18n.t('views.locations.show.facilities.show_less_link')
              : I18n.t('views.locations.show.facilities.show_more_link')
            }
          </span>
            { isOpen
              ? <SvgIco name="expand-less" size={24} />
              : <SvgIco name="expand-more" size={24} />
            }
          </div>
        }
      </div>
    </div>
  )
};


export default PopularAreas;
