import React from 'react';

import './Loader.sass'

const Loader = ({ size, left, top }) => {
  const style = { width: `${size}px`, height: `${size}px`, left: `${left}px`, top: `${top}px` };

  return (
    <div className="loader" style={style} />
  )
};

export default Loader;
