import React, { useCallback, useEffect, useState } from 'react'
import { SvgIco } from "../Icons";


export const usePrevNextButtons = (emblaApi) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, []);

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
};


export const PrevButton = (props) => {
  const { children, ...restProps } = props

  return (
    <div className='embla__button embla__button--prev' role="button" {...restProps}>
      <SvgIco className="embla__button__svg" name="keyboard-arrow-left-rounded" size={30} />
      { children }
    </div>
  )
};


export const NextButton = (props) => {
  const { children, ...restProps } = props

  return (
    <div className='embla__button embla__button--next' role="button" {...restProps}>
      <SvgIco className="embla__button__svg" name="keyboard-arrow-right-rounded" size={30} />
      { children }
    </div>
  )
};
