import React, { Fragment, useContext, useState } from 'react';

import * as helpers from '../../../utils/helpers';
import { SvgIco } from '../../SharedComponents/Icons';
import { Context } from '../index';

import './index.sass';


const BottomSeo = () => {

  const { bottomSeoBody, bottomSeoCompleteBody, bottomSeoHeadline } = useContext(Context);

  const [readMore, setReadMore] = useState(false);


  return (
    <div className="ep-bottom-seo bg-stage lazyload">
      <div className="container max-width">
        <div className="row">
          <div className="col-md-12">
            <div className="ep-bottom-seo__teaser-area">
              <div dangerouslySetInnerHTML={{ __html: helpers.parseMd(bottomSeoHeadline) }} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="ep-bottom-seo__body">
              <div className="ep-bottom-seo__columns">

                <span className="ep-bottom-seo__teaser">
                  <div dangerouslySetInnerHTML={{ __html: helpers.parseMd(bottomSeoBody) }} />
                </span>

                <span className={`ep-bottom-seo__complete ${ readMore && 'inline' }`}>
                  <div dangerouslySetInnerHTML={{ __html: helpers.parseMd(bottomSeoCompleteBody) }}/>
                </span>

                <span className="ep-bottom-seo__read-more" onClick={() => setReadMore(prevState => !prevState)}>
                  { readMore
                    ?
                      <Fragment>
                        { I18n.t('generic.close') }
                        <SvgIco name="expand-less" size={19} />
                      </Fragment>
                    :
                      <Fragment>
                        { I18n.t('locations.seo_friendly_summary.read_more') }
                        <SvgIco name="expand-more" size={19} />
                      </Fragment>
                  }
                </span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default BottomSeo;
