import React, { useContext, useEffect, useState, Suspense } from 'react';

import { apiProvider } from '../../../api/core';
import { Context } from '../index';

import './index.sass';


const NewsletterWidget = React.lazy(() => import('./NewsletterWidget'));


const NewsSection = () => {

  const {
    allNewsPath,
    newsArticlesPreviewPath,
    newsletterPath,
    siteKey,
  } = useContext(Context);

  const [articles, setArticles] = useState([]);
  const [resubscribeLink, setResubscribeLink] = useState('');
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const renderNewsletterWidget = siteKey === 'global' || siteKey === 'dk';
  const articlesData = articles && articles.length && (articles.length > 4 ? articles.slice(0, 4) : articles);


  useEffect(() => {
    apiProvider.getAll(newsArticlesPreviewPath, true)
      .then(response => response && setArticles(response))
      .catch(error => console.log('Error: ', error));
  }, []);


  const newsletterSubmitHandler = (data) => {
    const bodyFormData = new FormData();

    bodyFormData.append(`email[]`, data.email);
    bodyFormData.append('name', data.name);

    apiProvider.post(newsletterPath, bodyFormData, true)
      .then(response => {
        if (response.status === 200) {
          setSubmitSuccess(true);
        } else {
          setSubmitError(response.data.message || I18n.t('apps.entry_page.widgets.news_widget.backend-error'));
          setSubmitSuccess(false);
          setResubscribeLink(response.data.resubscribe_link || '');
        }
      }).catch(error => console.log('Error: ', error));
  };


  return (
    <div className="news-section container max-width">
      <div className="row">
        <div className="col-lg-8 col-xl-8">
          <div className="row news-section__articles-wrapper">

            <div className="col-xl-12">
              <p className="news-section__headline">
                { I18n.t('apps.entry_page.widgets.news_teasers.news_teasers_headline') }
              </p>
            </div>

            { !!(articlesData && articlesData.length) && articlesData.map(article =>
              <div className="col-xl-6 col-md-6 news-section__article-teaser" key={article.slug}>
                <a href={article.path} target="_blank">
                  <div className="news-section__article-img lazyload"
                       data-bg={article.image}
                       data-sizes="auto"
                       aria-labelledby="news-image"
                  />
                </a>

                <div className="news-section__article-info">
                  <div className="news-section__publish-date">
                    { article.publish_date}
                  </div>

                  <a className="news-section__article-link truncate-overflow"
                     href={`${article.path}`}
                     target="_blank">
                    { article.title }
                  </a>
                </div>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-xl-12 news-section__see-all-link-wrapper">
              <a className="news-section__see-all-link" href={allNewsPath} target="_blank">
                { I18n.t('apps.entry_page.widgets.news_teasers.see_all_news_link') }
              </a>
            </div>
          </div>
        </div>

        { renderNewsletterWidget &&
          <Suspense fallback={''}>
            <div className={`news-section__widget-wrapper col-lg-4 col-xl-4${submitSuccess ? ' success' : ''}`}>
              <NewsletterWidget
                submitError={submitError}
                submitSuccess={submitSuccess}
                resubscribeLink={resubscribeLink}
                newsletterSubmitHandler={newsletterSubmitHandler}
                hideSubmitError={() => setSubmitError(false)}
              />
            </div>
          </Suspense>
        }
      </div>
    </div>
  )
};

export default NewsSection;
